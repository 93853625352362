<template>

  <b-navbar-nav class="nav">
    <!--    <b-nav-item-->
    <!--      v-for="(bookmark2, index2) in localBookmarks.value"-->
    <!--      :id="`bookmark-${index2}`"-->
    <!--      :key="index2"-->
    <!--      :to="bookmark2.route"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        :icon="bookmark2.icon"-->
    <!--        size="21"-->
    <!--      />-->
    <!--    </b-nav-item>-->
    <!--    -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
    <b-nav-item-dropdown
      link-classes="bookmark-star"
      lazy
      @hidden="resetsearchQuery"
    >
      <feather-icon
        class="text-primary"
        slot="button-content"
        icon="FolderPlusIcon"
        size="18"
        style="height: 100%; padding-top: 3px"
      />

      <!-- Dropdown Content -->
      <li style="min-width:300px">
        <div class="p-1">
          <b-form-input
            id="boomark-search-input"
            v-model="searchQuery"
            placeholder="메뉴검색..."
            autofocus
          />
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-bookmark scroll-area"
          :class="{'show': filteredData.pages && filteredData.pages.length }"
          tagname="ul"
        >

          <b-dropdown-item
            v-for="(suggestion, index) in filteredData.pages || bookmarks"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            :to="suggestion.route"
            @mouseenter="currentSelected = index"
          >
            <feather-icon
              :icon="suggestion.icon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">{{ suggestion.title }}</span>
            <feather-icon
              icon="StarIcon"
              class="ml-auto"
              size="16"
              :class="{'text-primary': suggestion.isBookmarked}"
              @click.stop.prevent="toggleBookmarked(suggestion)"
            />

          </b-dropdown-item>
          <b-dropdown-item
            v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"
            disabled
          >
            No Results Found.
          </b-dropdown-item>
        </vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>
    <b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
    >
      <b-button
        variant="gradient-primary"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        class="reduced-padding font-small-1"
        style="margin-bottom: 4px; margin-right: -5px;"
      >
        {{ bookmark.title }}
      </b-button>
    </b-nav-item>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { computed, ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import searchAndBookmarkData2 from '@core/layouts/components/app-navbar/search-and-bookmark-data'


const searchAndBookmarkData = computed({
  get: () => store.state.bookmarkStore.searchAndBookmarkData,
})
const bookmarks = computed({
  get: () => store.state.bookmarkStore.bookmarks,
  set: val => {
    store.commit('bookmarkStore/toggleBookmarked', val)
  },
})

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, VuePerfectScrollbar, BDropdownItem, BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    //const searchAndBookmarkDataPages = ref(searchAndBookmarkData.value.pages)
    const searchAndBookmarkDataPages = ref(searchAndBookmarkData2.pages)
    console.log('bookmarks :::', bookmarks)
    console.log('searchAndBookmarkDataPages :::', searchAndBookmarkDataPages)
    console.log('searchAndBookmarkData2 :::', searchAndBookmarkData2.pages)
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({ data: { pages: searchAndBookmarkDataPages.value }, searchLimit: 6 })

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route).catch(() => {
      })
      resetsearchQuery()
    }

    const toggleBookmarked = item => {
      store.commit('bookmarkStore/toggleBookmarked', item)
    }

    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}

.reduced-padding {
  padding-left: 5px;  /* 원하는 값으로 조절하세요 */
  padding-right: 5px; /* 원하는 값으로 조절하세요 */
}
</style>
